var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row align-items-center" }, [
      _c("div", { staticClass: "col-md-6 col-xl-6" }, [
        _c(
          "div",
          { staticClass: "holdCard text-center" },
          [
            _c("div", [_vm._v(" HOLE CARDS ")]),
            _vm._l(_vm.holeCard, function(hole) {
              return _c("img", {
                key: hole,
                staticStyle: { "padding-right": "1%" },
                attrs: {
                  src: "/img/cards/" + hole + ".png",
                  alt: "logo",
                  width: "13%"
                }
              })
            })
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "gameResult text-center" },
          [
            _c("div", [_vm._v(" GAME RESULT ")]),
            _vm._l(_vm.gameResult, function(hole) {
              return _c("img", {
                key: hole,
                staticStyle: { "padding-right": "1%" },
                attrs: {
                  src: "/img/cards/" + hole + ".png",
                  alt: "logo",
                  width: "13%"
                }
              })
            })
          ],
          2
        )
      ]),
      _c("div", { staticClass: "col-md-6 col-xl-6" }, [
        _c("div", { staticClass: "betDetail" }, [
          _c(
            "div",
            {
              staticClass: "text-left",
              staticStyle: {
                "font-size": "1.5em",
                color: "#C6964B",
                "line-height": "3"
              }
            },
            [_vm._v(" BET DETAIL ")]
          ),
          _c("div", { staticClass: "row" }, [
            _vm._m(1),
            _c("div", { staticClass: "col-8" }, [
              _c("div", [
                _vm._v(
                  " " + _vm._s(_vm._f("date")(_vm.betDetail.dateTime)) + " "
                )
              ]),
              _c("div", [_vm._v(" " + _vm._s(_vm.betDetail.gameId) + " ")]),
              _c("div", [_vm._v(" " + _vm._s(_vm.betDetail.betId) + " ")]),
              _c("div", [
                _vm._v(
                  " " +
                    _vm._s(_vm._f("currency")(_vm.betDetail.potProfit)) +
                    " "
                )
              ]),
              _c(
                "div",
                {
                  style:
                    Math.sign(_vm.betDetail.winLoss || 0) < 0
                      ? "color: #FF000A"
                      : "color: #00FF38"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm._f("currency")(_vm.betDetail.winLoss)) +
                      " "
                  )
                ]
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "logoDetail" }, [
      _c("img", {
        attrs: { src: "/img/logo_3.png", alt: "logo", width: "20%" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-3" }, [
      _c("div", { staticClass: "text-left" }, [_vm._v(" Date Time ")]),
      _c("div", { staticClass: "text-left" }, [_vm._v(" Game ID ")]),
      _c("div", { staticClass: "text-left" }, [_vm._v(" BET ID ")]),
      _c("div", { staticClass: "text-left" }, [_vm._v(" POT PROFIT ")]),
      _c("div", { staticClass: "text-left" }, [_vm._v(" WIN/LOSS ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }