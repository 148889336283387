










































import { Component, Mixins } from "vue-property-decorator";
import BetDetailComponent from "./BetDetail.component";

@Component
export default class BetDetail extends Mixins(BetDetailComponent) {}
