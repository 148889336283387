import Model from "@/models/Model";
import { GET_BET_DETAIL_GAME_BY_ID } from "../../../../api/report";
import { Component, Vue } from "vue-property-decorator";
import moment from 'moment';
import 'vue2-datepicker/index.css';

@Component({
    filters: {
        date(val: any) {
            return val ? moment(val).local().format('DD-MM-YYYY HH:mm:ss') : ''
        },
        currency(value: any) {
            const val = (value / 1).toFixed(2).replace('.', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        upperCase(value: string) {
            return value.toUpperCase()
        },
        sCurrency(value: any) {
            return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        amountNumber(value: number) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
        checkName(value: string) {
            value = Vue.$cookies.get("login").username
            return value
        }
    }
})
default class BetDetail extends Vue {
    private model: Model;
    private holeCard = []
    private gameResult = []
    private betDetail = {
        dateTime: null,
        gameId: null,
        betId: null,
        winLoss: 0,
        potProfit: 0,
    }

    constructor() {
        super();
        this.model = new Model();
    }
    
    async mounted() {
        this.addRouteName()
        this.getBetDetailByGameandBetId()
    }

    private addRouteName() {
        this.model.Name = this.$route.name;
        this.$store
            .dispatch("addRouteName", this.model)
    }

    private async getBetDetailByGameandBetId(){
        const query = this.$route.query
        const response = await GET_BET_DETAIL_GAME_BY_ID(`${query.gameId}`, `${query.betId}`)
        this.betDetail.betId = response.data?.betId as any
        this.betDetail.gameId = response.data?.gameId as any
        this.betDetail.dateTime = response.data?.roundStartTime as any
        this.betDetail.potProfit = response.data?.potProfit as any
        this.betDetail.winLoss = response.data?.winloss as any
        this.holeCard = response.data?.holeCardResult[0].holeCard as any
        this.gameResult = response.data?.holeCardResult[0].bestHand as any
        console.log(response.data);
    }

}

export default BetDetail